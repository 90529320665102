import React from "react"
import { Row, Col } from "react-flexbox-grid"
import Hero from "../components/Hero"
import ContentBlock from "../components/ContentBlock"
import { Link } from "gatsby"
import homeHero from "../assets/images/header_home.jpg"

import "./index.scss"

const pageTitle = "Welcome to Harmony"
const pageDescription = "insert page description here"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    // {console.log('page level data', this.props);}
    return (
      <div id="home">
        <Hero className={"home"} bg={homeHero}>
          <h1>404 </h1>
          <p>The page can not be found</p>
          <Link to="/">
            <button aria-label="Go to Homepage">Go Harmony's homepage</button>
          </Link>
        </Hero>
      </div>
    )
  }
}

export default IndexPage
